import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import v1Routes from '@v1/router/index';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  ...v1Routes,
  {
    path: '/v2',
    component: () => import('../views/Index.vue'),
    children: [{
      path: 'achievement',
      component: () => import('../views/Achievement/Index.vue'),
      children: [{
        path: '',
        component: () => import('../views/Achievement/TabsIndex.vue'),
        children: [{
          name: 'AchievementMy',
          path: '',
          meta: {
            title: '业绩',
          },
          component: () => import(/* webpackChunkName: "achievementMy" */ '../views/Achievement/MyAchievement.vue'),
        }, {
          name: 'AchievementTeam',
          path: 'team',
          meta: {
            title: '业绩',
          },
          component: () => import(/* webpackChunkName: "team" */ '../views/Achievement/TeamAchievement.vue'),
        }],
      }, {
        name: 'AchievementMyCustomer',
        path: 'myCustomer',
        meta: {
          title: '我的客户',
        },
        component: () => import(/* webpackChunkName: "myCustomer" */ '../views/Achievement/MyCustomer.vue'),
      }, {
        name: 'AchievementMyCustomerDetail',
        path: 'myCustomerDetail',
        meta: {
          title: '我的客户',
        },
        component: () => import(/* webpackChunkName: "myCustomerDetail" */ '../views/Achievement/MyCustomerDetail.vue'),
      }],
    }, {
      path: 'prducts',
      component: () => import('../views/Products/Index.vue'),
      children: [{
        name: 'PrductsInputInfo',
        path: 'inputInfoList',
        meta: {
          title: '申请',
        },
        component: () => import('../views/Products/InputInfoList.vue'),
      }, {
        name: 'PrductsInputInfoStep1',
        path: 'inputInfoStep1',
        component: () => import('../views/Products/InputInfoStep1.vue'),
      }, {
        name: 'PrductsInputInfoStep2',
        path: 'inputInfoStep2',
        component: () => import('../views/Products/InputInfoStep2.vue'),
      }, {
        name: 'PrductsInputInfoStep3',
        path: 'inputInfoStep3',
        component: () => import('../views/Products/InputInfoStep3.vue'),
      }, {
        name: 'PrductsInputInfoStep4',
        path: 'inputInfoStep4',
        component: () => import('../views/Products/InputInfoStep4.vue'),
      }, {
        name: 'PrductsContinueAddEnclosure',
        path: 'continueAddEnclosure',
        component: () => import('../views/Products/ContinueAddEnclosure.vue'),
      }, {
        name: 'MerchantAddProducts',
        path: 'merchantAddProducts',
        component: () => import('../views/Products/MerchantAddProducts.vue'),
      }],
    }, {
      path: 'workbench',
      component: () => import('../views/Workbench/Index.vue'),
      children: [{
        name: 'Workbench',
        path: '',
        meta: {
          title: '工作台',
        },
        component: () => import(/* webpackChunkName: "workbench" */ '../views/Workbench/Workbench.vue'),
      }, {
        name: 'WorkbenchApprovalDetails',
        path: 'approvalDetails',
        component: () => import(/* webpackChunkName: "approvalDetails" */ '../views/Workbench/ApprovalDetails.vue'),
      }, {
        name: 'ApprovalCenter',
        meta: {
          title: '审批中心',
        },
        path: 'approvalCenter',
        component: () => import('../views/Workbench/approvalCenter.vue'),
      },
      {
        name: 'ApprovalDetails_v3',
        path: 'approvalDetails_v3',
        component: () => import(/* webpackChunkName: "approvalDetails" */ '../views/Workbench/ApprovalDetails_v3.vue'),
      }],
    }, {
      path: 'unionpay',
      component: () => import('../views/UnionPay/Index.vue'),
      children: [{
        name: 'unionpay',
        path: '',
        meta: {
          title: '佣金提现',
        },
        component: () => import(/* webpackChunkName: "workbench" */ '../views/UnionPay/YjWithdraw.vue'),
      }, {
        name: 'unionpayWithdrawResult',
        path: 'withdrawResult',
        meta: {
          title: '提现结果',
        },
        component: () => import(/* webpackChunkName: "withdrawResult" */ '../views/UnionPay/WithdrawResult.vue'),
      }, {
        name: 'unionpayMyReturnCommission',
        path: 'myReturnCommission',
        meta: {
          title: '我的',
        },
        component: () => import(/* webpackChunkName: "myReturnCommission" */ '../views/UnionPay/MyReturnCommission.vue'),
      }, {
        name: 'unionpayMiddlePage',
        path: 'middlePage',
        component: () => import(/* webpackChunkName: "middlePage" */ '../views/UnionPay/MiddlePage.vue'),
      }, {
        name: 'unionpayChangePasswordResult',
        path: 'changePasswordResult',
        meta: {
          title: '密码设置成功',
        },
        component: () => import(/* webpackChunkName: "changePasswordResult" */ '../views/UnionPay/ChangePasswordResult.vue'),
      }, {
        name: 'unionpayChangePassword',
        path: 'passwordChange',
        meta: {
          title: '修改支付密码',
        },
        component: () => import(/* webpackChunkName: "passwordChange" */ '../views/UnionPay/PasswordChange.vue'),
      }, {
        name: 'unionpayCardsList',
        path: 'cardsList',
        meta: {
          title: '银行卡中心',
        },
        component: () => import(/* webpackChunkName: "cardsList" */ '../views/UnionPay/CardsList.vue'),
      }, {
        name: 'unionpayBindUnionPaySuccess',
        path: 'bindUnionPaySuccess',
        meta: {
          title: '签约成功',
        },
        component: () => import(/* webpackChunkName: "bindUnionPaySuccess" */ '../views/UnionPay/BindUnionPaySuccess.vue'),
      }, {
        name: 'unionpayWithdrawDetail',
        path: 'withdrawDetail',
        meta: {
          title: '提现明细',
        },
        component: () => import(/* webpackChunkName: "withdrawDetail" */ '../views/UnionPay/WithdrawDetail.vue'),
      }, {
        name: 'unionpayFyDetail',
        path: 'fyDetail',
        meta: {
          title: '返佣明细',
        },
        component: () => import(/* webpackChunkName: "fyDetail" */ '../views/UnionPay/FyDetail.vue'),
      }, {
        name: 'unionpayBindingWeChat',
        path: 'bindingWeChat',
        meta: {
          title: '绑定微信',
        },
        component: () => import(/* webpackChunkName: "bindingWeChat" */ '../views/UnionPay/BindingWeChat.vue'),
      }, {
        name: 'unionpayUnbindCard',
        path: 'unbindCard',
        meta: {
          title: '银行卡详情',
        },
        component: () => import(/* webpackChunkName: "unbindCard" */ '../views/UnionPay/UnbindCard.vue'),
      }, {
        name: 'unionpayUnbindCardInPassword',
        path: 'unbindCardInPassword',
        meta: {
          title: '解绑银行卡',
        },
        component: () => import(/* webpackChunkName: "unbindCardInPassword" */ '../views/UnionPay/UnbindCardInPassword.vue'),
      }, {
        name: 'unionpayAuthSuccess',
        path: 'authSuccess',
        meta: {
          title: '认证结果',
        },
        component: () => import(/* webpackChunkName: "authSuccess" */ '../views/UnionPay/AuthSuccess.vue'),
      }, {
        name: 'unionpayOpenAccount',
        path: 'openAccount',
        meta: {
          title: '开通账户',
        },
        component: () => import(/* webpackChunkName: "openAccount" */ '../views/UnionPay/OpenAccount.vue'),
      }, {
        name: 'unionpayAuthInfo',
        path: 'authInfo',
        meta: {
          title: '实名认证',
        },
        component: () => import(/* webpackChunkName: "authInfo" */ '../views/UnionPay/AuthInfo.vue'),
      }, {
        name: 'unionpayAddCard',
        path: 'addCard',
        meta: {
          title: '绑定银行卡',
        },
        component: () => import(/* webpackChunkName: "authInfo" */ '../views/UnionPay/AddCard.vue'),
      }, {
        name: 'unionpayChangeBindPhone',
        path: 'changeBindPhone',
        meta: {
          title: '换绑手机号',
        },
        component: () => import(/* webpackChunkName: "changeBindPhone" */ '../views/UnionPay/ChangeBindPhone.vue'),
      }],
    }, {
      path: 'invitation',
      component: () => import('../views/Invitation/index.vue'),
      children: [{
        name: 'inviteesSetPhone',
        path: 'inviteesSetPhone',
        meta: {
          title: '填写手机号',
        },
        component: () => import('../views/Invitation/inviteesSetPhone.vue'),
      }],
    },
    {
      path: 'approval',
      component: () => import('../views/Approval/index.vue'),
      children: [
        {
          name: 'ApprovalList',
          path: 'approvalList',
          meta: {
            title: '审批中心',
          },
          component: () => import('../views/Approval/approvalList.vue'),
        },
        {
          name: 'OrderApproval',
          path: 'orderApproval',
          meta: {
            title: '订单审批',
          },
          component: () => import('../views/Approval/orderApproval.vue'),
        },
        {
          path: 'orderDetails',
          component: () => import('../views/Approval/orderDetails/index.vue'),
          children: [
            {
              name: 'View',
              path: 'view',
              meta: {
                title: '订单详情',
              },
              component: () => import('../views/Approval/orderDetails/view.vue'),
            },
            {
              name: 'MortgageView',
              path: 'mortgageView',
              meta: {
                title: '订单详情',
              },
              component: () => import('../views/Approval/orderDetails/mortgageView.vue'),
            },
            {
              name: 'Edit',
              path: 'edit',
              meta: {
                title: '订单详情',
              },
              component: () => import('../views/Approval/orderDetails/edit.vue'),
            },
            {
              name: 'ApprovalReturn',
              path: 'approvalReturn',
              meta: {
                title: '订单详情',
              },
              component: () => import('../views/Approval/orderDetails/return.vue'),
            },
          ],
        },
      ],
    }],
  },
  {
    path: '/test',
    name: 'Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
  },
  {
    path: '*',
    component: () => import('@v1/page/404.vue'),
    meta: { title: '404' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
