// import Vue from "vue";
// import Router from "vue-router";

// Vue.use(Router);

export default [
    {
      name: 'v1-Team',
      path: "/",
      component: () => import("@v1/page/Index/Index.vue"),
      meta: { title: "团队" },
    },
    {
      path: "/teamSecondLevel",
      meta: { title: "团队" },
      component: () => import("@v1/page/Index/TeamSecondLevel"),
    },
    {
      name: 'v1-Login',
      path: "/login",
      component: () => import("@v1/page/Login/Login.vue"),
      meta: { title: "登录" },
    },
    {
      path: "/verifyCodeLogin",
      component: () => import("@v1/page/Login/VerifyCodeLogin.vue"),
      meta: { title: "短信验证登录" },
    },
    {
      path: "/register",
      component: () => import("@v1/page/Login/Register.vue"),
      meta: { title: "注册" },
    },
    {
      path: "/findPasswd",
      component: () => import("@v1/page/Login/Password/FindPasswd.vue"),
      meta: { title: "找回密码" },
    },
    {
      path: "/modifyPasswd",
      component: () => import("@v1/page/User/ModifyPasswd/Index.vue"),
      meta: { title: "修改密码" },
    },
    {
      path: "/inputVerifyCode",
      component: () => import("@v1/page/Login/Password/InputVerifyCode.vue"),
      meta: { title: "输入验证码" },
    },
    {
      path: "/resetPasswd",
      component: () => import("@v1/page/Login/Password/ResetPasswd.vue"),
      meta: { title: "重置密码" },
    },
    {
      path: "/setPasswd",
      component: () => import("@v1/page/Login/FirstLogin/SetPasswd.vue"),
      meta: { title: "设置密码" },
    },
    {
      path: "/completeInfo",
      component: () => import("@v1/page/Login/FirstLogin/setPersonInfo.vue"),
      meta: { title: "完善资料" },
    },
    {
      name: 'v1-Product',
      path: "/product",
      component: () => import("@v1/page/Product/Product.vue"),
      meta: { title: "产品" },
    },
    {
      name: 'v1-User',
      path: "/user",
      component: () => import("@v1/page/User/User.vue"),
      meta: { title: "我的" },
    },
    {
      path: "/modifyCard",
      component: () => import("@v1/page/User/BusinessCard/Index.vue"),
      meta: { title: "修改名片" },
    },
    {
      path: "/directory",
      name:'directory',
      component: () => import("@v1/page/User/Directory/directory.vue"),
      meta: { title: "权限管理" },
    },
    {
      path: "/teamManage",
      name: 'teamManage',
      component: () => import("@v1/page/User/TeamManage/TeamMange.vue"),
      meta: { title: "团队管理" },
    },
    {
      path: "/staffSetting",
      name: 'staffSetting',
      component: () => import("@v1/page/User/StaffSetting/staffSetting.vue"),
      meta: { title: "企业员工管理" },
    },
    {
      path: "/DeleteMember",
      component: () => import("@v1/page/User/AuthoritySetting/DeleteMember.vue"),
      meta: { title: "团员权限管理" },
    },
    {
      path: "/authoritySetting",
      name: 'authoritySetting',
      component: () =>
        import("@v1/page/User/AuthoritySetting/AuthoritySetting.vue"),
      meta: { title: "权限管理" },
    },
    {
      name:"enterpriseAuthorize",
      path: "/enterpriseAuthorize",
      component: () =>
        import("@v1/page/User/AuthoritySetting/EnterpriseAuthorize.vue"),
      meta: { title: "企业权限管理" },
    },
    {
      name:"permissionSetting",
      path: "/permissionSetting",
      component: () =>
        import("@v1/page/User/AuthoritySetting/PermissionSetting.vue"),
    },
    {
      path: "/permissDetail",
      component: () => import("@v1/page/User/AuthoritySetting/PermissDetail.vue"),
    },
    {
      path: "/accountSetting",
      name: 'accountSetting',
      component: () => import("@v1/page/User/AccountSetting/AccountSetting.vue"),
      meta: { title: "设置" },
    },
    {
      path: "/verify",
      component: () => import("@v1/page/User/ModifyPhone/Verify.vue"),
      meta: { title: "修改手机号" },
    },
    {
      path: "/setNewPhone",
      component: () => import("@v1/page/User/ModifyPhone/SetNewPhone.vue"),
      meta: { title: "修改手机号" },
    },
    {
      path: "/inviteProcess",
      component: () => import("@v1/page/User/TeamManage/inviteProcess.vue"),
      meta: { title: "邀请流程" },
    },
    {
      path: "/teamGroup",
      component: () => import("@v1/page/User/TeamManage/TeamGroupManage.vue"),
      meta: { title: "团队结构配置" },
    },
    {
      path: "/businessCard",
      component: () => import("@v1/page/User/BusinessCard.vue"),
      meta: { title: "名片" },
    },
    {
      path: "/invite",
      component: () => import("@v1/page/Invite/Index"),
      meta: { title: "邀请注册" },
      children: [
        {
          path: "fillInPhone",
          meta: { title: "邀请注册" },
          component: () => import("@v1/page/Invite/FillInPhone"),
        },
        {
          path: "fillInVerificationCode",
          meta: { title: "邀请注册" },
          component: () => import("@v1/page/Invite/FillInVerificationCode"),
        },
        {
          path: "result",
          meta: { title: "结果页面" },
          component: () => import("@v1/page/Invite/Result"),
        },
        {
          path: "querystate",
          meta: { title: "审核状态查询" },
          component: () => import("@v1/page/Invite/Querystate"),
        },
      ],
    },
    {
      path: "/message",
      component: () => import("@v1/page/Message/Index.vue"),
      children: [
        {
          name: 'MessageList',
          path: "list",
          meta: { title: "消息中心" },
          component: () => import("@v1/page/Message/List.vue"),
        },
        {
          path: "detail",
          meta: { title: "消息中心" },
          component: () => import("@v1/page/Message/Detail.vue"),
        },
      ],
    },
    {
      path: "/search",
      meta: { title: "搜索" },
      component: () => import("@v1/page/Search/All"),
    },
    {
      name: 'enterpriseManage',
      path: "/enterpriseManage",
      meta: { title:"企业管理" },
      component: () => import("@v1/page/User/enterpriseManage/index.vue")
    }
    // {
    //   path: "*",
    //   component: () => import("@v1/page/404.vue"),
    //   meta: { title: "404" },
    // },
  ];

// const VueRouterPush = Router.prototype.push;
// Router.prototype.push = function push(to) {
//   return VueRouterPush.call(this, to).catch((err) => err);
// };
