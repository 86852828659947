import Vue from 'vue';
import Vuex from 'vuex';
import { MsgGetUnreadCountResponse, msgGetUnreadCount } from '@/api/msg';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    message: {
      unReadCount: 0,
      hasUnReadMsg: 'N',
    },
  },
  mutations: {
    set_messageNum: (state, data:MsgGetUnreadCountResponse) => {
      state.message = data;
    },
  },
  actions: {
    async setMessageNum({ commit }) {
      const { data } = await msgGetUnreadCount();
      commit('set_messageNum', data);
    },
  },
  modules: {
  },
});
