/* prettier-ignore-start */
/* tslint:disable */
/* eslint-disable */

/* 该文件由 yapi-to-typescript 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from 'yapi-to-typescript'
// @ts-ignore
import request from './request'

type UserRequestRestArgs = RequestFunctionRestArgs<typeof request>

// Request: 目前 React Hooks 功能有用到
export type Request<
  TRequestData,
  TRequestConfig extends RequestConfig,
  TRequestResult,
> = (TRequestConfig['requestDataOptional'] extends true
  ? (requestData?: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult
  : (requestData: TRequestData, ...args: RequestFunctionRestArgs<typeof request>) => TRequestResult) & {
  requestConfig: TRequestConfig
}

const mockUrl_0_0_0_6 = 'http://172.30.1.220:3000/mock/118' as any
const devUrl_0_0_0_6 = 'http://hhrgw.mspartnersaas.com' as any
const prodUrl_0_0_0_6 = 'https://partner.saas.com' as any
const dataKey_0_0_0_6 = 'data' as any

/**
 * 接口 [打招呼↗](http://172.30.1.220:3000/project/118/interface/api/2075) 的 **请求类型**
 *
 * @分类 [saas3.0审批中心↗](http://172.30.1.220:3000/project/118/interface/api/cat_511)
 * @请求头 `POST /msg/sayHi`
 * @更新时间 `2022-03-26 15:00:59`
 */
export interface MsgSayHiRequest {
  /**
   * 消息ID
   */
  msgId: string
}

/**
 * 接口 [打招呼↗](http://172.30.1.220:3000/project/118/interface/api/2075) 的 **返回类型**
 *
 * @分类 [saas3.0审批中心↗](http://172.30.1.220:3000/project/118/interface/api/cat_511)
 * @请求头 `POST /msg/sayHi`
 * @更新时间 `2022-03-26 15:00:59`
 */
export interface MsgSayHiResponse {
  /**
   * 1成功 2失败
   */
  status: string
}

/**
 * 接口 [打招呼↗](http://172.30.1.220:3000/project/118/interface/api/2075) 的 **请求配置的类型**
 *
 * @分类 [saas3.0审批中心↗](http://172.30.1.220:3000/project/118/interface/api/cat_511)
 * @请求头 `POST /msg/sayHi`
 * @更新时间 `2022-03-26 15:00:59`
 */
type MsgSayHiRequestConfig = Readonly<
  RequestConfig<
    'http://172.30.1.220:3000/mock/118',
    'http://hhrgw.mspartnersaas.com',
    'https://partner.saas.com',
    '/msg/sayHi',
    'data',
    string,
    'msgId',
    false
  >
>

/**
 * 接口 [打招呼↗](http://172.30.1.220:3000/project/118/interface/api/2075) 的 **请求配置**
 *
 * @分类 [saas3.0审批中心↗](http://172.30.1.220:3000/project/118/interface/api/cat_511)
 * @请求头 `POST /msg/sayHi`
 * @更新时间 `2022-03-26 15:00:59`
 */
const msgSayHiRequestConfig: MsgSayHiRequestConfig = {
  mockUrl: mockUrl_0_0_0_6,
  devUrl: devUrl_0_0_0_6,
  prodUrl: prodUrl_0_0_0_6,
  path: '/msg/sayHi',
  method: Method.POST,
  requestHeaders: {},
  requestBodyType: RequestBodyType.form,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_6,
  paramNames: [],
  queryNames: ['msgId'],
  requestDataOptional: false,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'msgSayHi',
}

/**
 * 接口 [打招呼↗](http://172.30.1.220:3000/project/118/interface/api/2075) 的 **请求函数**
 *
 * @分类 [saas3.0审批中心↗](http://172.30.1.220:3000/project/118/interface/api/cat_511)
 * @请求头 `POST /msg/sayHi`
 * @更新时间 `2022-03-26 15:00:59`
 */
export const msgSayHi = (requestData: MsgSayHiRequest, ...args: UserRequestRestArgs) => {
  return request<MsgSayHiResponse>(prepare(msgSayHiRequestConfig, requestData), ...args)
}

msgSayHi.requestConfig = msgSayHiRequestConfig

const mockUrl_0_0_0_8 = 'http://172.30.1.220:3000/mock/118' as any
const devUrl_0_0_0_8 = 'http://hhrgw.mspartnersaas.com' as any
const prodUrl_0_0_0_8 = 'https://partner.saas.com' as any
const dataKey_0_0_0_8 = 'data' as any

/**
 * 接口 [获取未读消息数↗](http://172.30.1.220:3000/project/118/interface/api/2163) 的 **请求类型**
 *
 * @分类 [合伙人4.0 一期 审批接口↗](http://172.30.1.220:3000/project/118/interface/api/cat_523)
 * @请求头 `GET /msg/getUnreadCount`
 * @更新时间 `2022-07-26 10:52:59`
 */
export interface MsgGetUnreadCountRequest {}

/**
 * 接口 [获取未读消息数↗](http://172.30.1.220:3000/project/118/interface/api/2163) 的 **返回类型**
 *
 * @分类 [合伙人4.0 一期 审批接口↗](http://172.30.1.220:3000/project/118/interface/api/cat_523)
 * @请求头 `GET /msg/getUnreadCount`
 * @更新时间 `2022-07-26 10:52:59`
 */
export interface MsgGetUnreadCountResponse {
  /**
   * 未读数
   */
  unReadCount: number
  /**
   * "Y","N"
   */
  hasUnReadMsg: string
}

/**
 * 接口 [获取未读消息数↗](http://172.30.1.220:3000/project/118/interface/api/2163) 的 **请求配置的类型**
 *
 * @分类 [合伙人4.0 一期 审批接口↗](http://172.30.1.220:3000/project/118/interface/api/cat_523)
 * @请求头 `GET /msg/getUnreadCount`
 * @更新时间 `2022-07-26 10:52:59`
 */
type MsgGetUnreadCountRequestConfig = Readonly<
  RequestConfig<
    'http://172.30.1.220:3000/mock/118',
    'http://hhrgw.mspartnersaas.com',
    'https://partner.saas.com',
    '/msg/getUnreadCount',
    'data',
    string,
    string,
    true
  >
>

/**
 * 接口 [获取未读消息数↗](http://172.30.1.220:3000/project/118/interface/api/2163) 的 **请求配置**
 *
 * @分类 [合伙人4.0 一期 审批接口↗](http://172.30.1.220:3000/project/118/interface/api/cat_523)
 * @请求头 `GET /msg/getUnreadCount`
 * @更新时间 `2022-07-26 10:52:59`
 */
const msgGetUnreadCountRequestConfig: MsgGetUnreadCountRequestConfig = {
  mockUrl: mockUrl_0_0_0_8,
  devUrl: devUrl_0_0_0_8,
  prodUrl: prodUrl_0_0_0_8,
  path: '/msg/getUnreadCount',
  method: Method.GET,
  requestHeaders: {},
  requestBodyType: RequestBodyType.query,
  responseBodyType: ResponseBodyType.json,
  dataKey: dataKey_0_0_0_8,
  paramNames: [],
  queryNames: [],
  requestDataOptional: true,
  requestDataJsonSchema: {},
  responseDataJsonSchema: {},
  requestFunctionName: 'msgGetUnreadCount',
}

/**
 * 接口 [获取未读消息数↗](http://172.30.1.220:3000/project/118/interface/api/2163) 的 **请求函数**
 *
 * @分类 [合伙人4.0 一期 审批接口↗](http://172.30.1.220:3000/project/118/interface/api/cat_523)
 * @请求头 `GET /msg/getUnreadCount`
 * @更新时间 `2022-07-26 10:52:59`
 */
export const msgGetUnreadCount = (requestData?: MsgGetUnreadCountRequest, ...args: UserRequestRestArgs) => {
  return request<MsgGetUnreadCountResponse>(prepare(msgGetUnreadCountRequestConfig, requestData), ...args)
}

msgGetUnreadCount.requestConfig = msgGetUnreadCountRequestConfig

/* prettier-ignore-end */
