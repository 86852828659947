import Vue from 'vue'
import Vuex from 'vuex';
import util from '@v1/tools/util';
import router from '@/router/index';

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        token:'',
        inviteCode: '',
        internal:''
    },
    mutations:{
        SET_TOKEN(token) {
            this.token = token;
        },
        REMOVE_TOKEN() {
            this.token = '';
        },
        SET_INVITECODE(CODE) {
            this.inviteCode = CODE;
        },
        SET_INTERNAL(CODE){
            this.internal = CODE;
        },
        REMOVE_CODE() {
            this.inviteCode = '';
        }
    },
    actions:{
        SetToken(ctx,token) {
            if (token) {
                ctx.commit('SET_TOKEN', token);
                window.localStorage.token = token;
            } else {
                const tk = window.localStorage.token;
                if (tk) {
                    ctx.commit('SET_TOKEN', tk);
                }
            }
        },
        RemoveToken(){
            this.commit('REMOVE_TOKEN');
            window.localStorage.removeItem('token');
        },
        SetInviteCode(ctx, code) {
            if(code) {
                ctx.commit('SET_INVITECODE', code);
                window.localStorage.inviteCode = code;
            } else {
                const cd = window.localStorage.inviteCode;
                if(cd ) {
                    ctx.commit('SET_INVITECODE', cd);
                }
            }
        },
        SetInternal({ commit }, code) {
            if(code) {
                commit('SET_INTERNAL', code);
                window.localStorage.internal = code;
            } else {
                const cd = window.localStorage.internal;
                if(cd ) {
                    commit('SET_INTERNAL', cd);
                }
            }
        },
        RemoveCode() {
            this.commit('REMOVE_CODE');
            window.localStorage.removeItem('inviteCode');
        },
        ToLogin(){
            if (util.isMsApp()) {
                window.$bridge.iosSend('login', {});
            } else if (util.isWeiXin()) {
                // TODO:添加微信登录
                sessionStorage.setItem('preUrl', location.pathname);
                router.replace({ name: 'v1-Login' });
            } else {
                // Vue.prototype.$MsLogin();
                if(this.state.token) {
                    sessionStorage.setItem('preUrl', location.pathname);
                }
                router.replace({ name: 'v1-Login' });
            }
        }
    }
    
})
export default store;