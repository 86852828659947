import Vue, { VueConstructor } from 'vue';
import { Toast } from 'vant';
import { CommonResponseType, RequestError } from '@/api/request';

Vue.use(Toast);

export default {
  install: (Vue: VueConstructor, options = {}): void => {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$errorHandler = (err: Error | CommonResponseType<any>) => {
      console.error('errorHandler: ', err);
      if ('msg' in err) {
        Toast.fail(err.msg);
      } else if (err instanceof RequestError) {
        Toast.fail(err.message.message);
      } else if (err instanceof Error) {
        Toast.fail(err.message);
      } else {
        try {
          Toast.fail(JSON.stringify(err));
        } catch (e) {
          Toast.fail(`未知错误: ${e}`);
        }
      }
    };
  },
};
