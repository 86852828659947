export default {
  isWeiXin: () => /MicroMessenger/.test(navigator.userAgent),
  isAndroid: () => /android/i.test(navigator.userAgent),
  isIphone: () => /iphone/i.test(navigator.userAgent),
  isIOS: () => /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent),
  isMsydApp: () => /msyd/i.test(navigator.userAgent),
  isMszlApp: () => /mszl/i.test(navigator.userAgent),
  isMsydAndroid: () => /msyd/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent),
  isMsydIphone: () => /msyd/i.test(navigator.userAgent) && /iphone/i.test(navigator.userAgent),
  isMsApp: () => /msyd|mszl|msjk/i.test(navigator.userAgent),
  // 登录手机号正则
  loginPhoneReg: (phone) => /^1[3456789]\d{9}$/.test(phone),
  // 名字。2-20个字符
  nameReg: (val) => /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/.test(val),
  // 身份证
  idNoReg: (val) => /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(val),
  // 银行卡号
  cardReg: (val) => {
    const nVal = val.replace(/\s+/g, '');
    return (/^(\d{16,20})$/.test(nVal));
  },
  // 密码
  passwordReg: (val) => /\d{6}$/.test(val),
  // 6位纯数字验证码
  vCode: (val) => /^\d{6}$/.test(val),
  // 数字密码
  numPasswdReg: (val) => /^\d+$/.test(val),

  //常数密码8-20
  passwdReg: (val) => /^\w{8,20}$/.test(val)
};
