import Vue from 'vue';
import { Toast } from 'vant';
import App from './App.vue';
// import './registerServiceWorker';
import router from './router';
import store from './store';
import 'normalize.css';
import 'vant/lib/index.css';
import '@/assets/styles/reset.less';
import errorHandler from './service/plugins/errorHandler';
import '@v1/tools/directive';

Vue.config.productionTip = false;

Vue.use(Toast);
Vue.use(errorHandler);

router.beforeEach((to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
